const usersRoutes = [
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "chunk-users" */ '@/pages/users/users.vue'),
    meta: {
      authorities: ['ROLE_PROFESSOR'],
      breadcrumb: [{ text: 'Users' }],
      title: 'Operisoft - Users',
    },
  },
  {
    path: '/users/new',
    name: 'new_user',
    component: () => import(/* webpackChunkName: "chunk-users" */ '@/pages/users/user-new.vue'),
    meta: {
      authorities: ['ROLE_PROFESSOR'],
      breadcrumb: [{ text: 'Users' }],
      title: 'Operisoft - New User',
    },
  },
  {
    path: '/users/Import_user',
    name: 'Importuser',
    component: () => import(/* webpackChunkName: "chunk-users" */ '@/pages/users/import-user.vue'),
    meta: {
      authorities: ['ROLE_PROFESSOR'],
      breadcrumb: [{ text: 'Users/ Import users' }],
      title: 'Operisoft - Import user',
    },
  },
  {
    path: '/users/:username',
    name: 'user_edition',
    component: () => import(/* webpackChunkName: "chunk-users" */ '@/pages/users/user-edit.vue'),
    meta: {
      authorities: ['ROLE_PROFESSOR'],
      breadcrumb: [{ text: 'Users', to: { name: 'users' } }, { text: 'User edition' }],
      title: 'Operisoft - Edit User',
    },
    props: true,
  },
  {
    path: '/organizations',
    name: 'organizations',
    component: () => import(/* webpackChunkName: "chunk-users" */ '@/pages/users/organizations.vue'),
    meta: {
      authorities: ['ROLE_ADMIN'],
      breadcrumb: [{ text: 'Organizations' }],
      title: 'Operisoft - Organization',
    },
    props: true,
  },
];

export default usersRoutes;
