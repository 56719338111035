<template>
  <b-nav-item-dropdown>
    <template v-slot:button-content>
        <font-awesome-icon
        id="user_icon"
        :icon="['far', 'user']"
        />
      <span id="user.name">{{ user.username }}</span>
    </template>
    <b-dropdown-item @click="doLogout">
      <span>Log Out</span>
      <font-awesome-icon
      icon="sign-out-alt"
      class="sign-out-icon"
      />
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'AppTopBarUser',
    computed: {
      ...mapState('session', {
        user: 'user',
      }),
    },
    methods: {
      doLogout() {
        this.$store.dispatch('session/logout').then(() => this.$router.push('/login'));
      },
    },
  };
</script>

<style scoped>
  .dropdown {
    padding: 4px 0 4px 20px;
    line-height: 35px;
  }

  /deep/ .dropdown-toggle {
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color: #fff !important;
    padding: .5rem 1rem !important;
  }

  /deep/ .dropdown-toggle span {
    font-size: 13px;
    font-weight: 500;
    padding: 0 35px 0 10px;
    color: #06090D;
  }

  /deep/ .dropdown-menu {
    border: none;
    border-radius: 0;
    margin: 0;
    width: 100%;
  }

  /deep/ .dropdown-item {
    font-size: 15px;
    color: #15283c;
    padding: 4px 15px;
    border-bottom: none;
    line-height: normal;
  }

  /deep/ .dropdown-item span {
    font-size: 13px;
    margin: auto;
    display: block;
    color: rgb(255, 255, 255);
  }
  .dropdown-toggle:after {
    color: #06090D;
  }
  #user.name{
    color: #06090D;
  }
  svg#user_icon {
    color: black;
    font-size: 16px;
}
.sign-out-icon{
  color: #06090D;
}
</style>
