<template>
  <b-navbar toggleable="lg">
    <b-navbar-toggle target="nav-collapse" />

    <b-collapse
      id="nav-collapse"
      is-nav
    >
      <b-nav-item
        v-for="entry in authorizedEntries"
        :key="entry.route"
        :to="{ name: entry.route }"
        active-class="active"
        link-classes="rippled"
      >
        <font-awesome-icon
          :icon="entry.icon"
          :class="entry.class + ' icon'"
        />
        <!-- <span v-if="!collapse">{{ entry.title }}</span> -->
        <span>{{ entry.title }}</span>
      </b-nav-item>
    </b-collapse>
  </b-navbar>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'AppSideBarLinks',
    // props: {
    //   collapse: { type: Boolean, required: true },
    // },
    data: () => ({
      entries: [
        {
          route: 'dashboard', icon: 'tachometer-alt', class: 'yellow_color', title: 'Dashboard', roles: ['ROLE_STUDENT'],
        },
        {
          route: 'modules', icon: 'object-group', class: 'blue1_color', title: 'Lab Infra', roles: ['ROLE_PROFESSOR'],
        },
        {
          route: 'stacks', icon: 'layer-group', class: 'blue2_color', title: 'Practical Assignments', roles: ['ROLE_STUDENT'],
        },
        {
          route: 'credentialsList', icon: 'lock', class: 'orange_color', title: 'Credentials', roles: ['ROLE_ADMIN'],
        },
        {
          route: 'settings', icon: 'cog', class: 'yellow_color', title: 'Settings', roles: ['ROLE_SUPERADMIN'],
        },
        {
          route: 'users', icon: 'user-friends', class: 'yellow_color', title: 'Users', roles: ['ROLE_PROFESSOR'],
        },
        {
          route: 'labs', icon: 'file', class: 'yellow_color', title: 'User Group', roles: ['ROLE_PROFESSOR'],
        },
        {
          route: 'organizations', icon: 'sitemap', class: 'yellow_color', title: 'Department', roles: ['ROLE_SUPERADMIN'],
        },
      ],
    }),
    computed: {
      ...mapGetters('session', ['hasAuthorities']),
      authorizedEntries() {
        return this.entries.filter((entry) => this.hasAuthorities(entry.roles));
      },
    },
  };
</script>

<style scoped>
  .nav {
    display: flex;
    flex-direction: row;
  }
  .nav-item {
    display: flex;
    flex-direction: row;
  }
  li.nav-item{
    border-radius: 100px;
    margin-left: 15px;
    padding: -19px -16px;
    /* padding-top: 4px; */
    height: 40px;
    width: auto;
  }
  li.nav-item:hover{
    background-color: #1e3633;
  }

  .nav-link {
    color: #222;
    padding: 15px 25px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    line-height: 20px;
    white-space: nowrap;
    color: #00ab94;
    font-size: 15px;
    margin-top: -11px;
  }
    .nav-link .icon{
      color: #065090 ;
    }

  .nav-link.active {
    /* -webkit-box-shadow: inset 4px 0 0 #00ab94;
    box-shadow: inset 4px 0 0 #00ab94; */
    /* background: rgba(0,171,148,.1); */
    color: #00ab94;
    font-size: 15px;
    margin-top: -11px;
  }

  .nav-link.active .icon {
    color: #00ab94 !important;
  }

  /* .nav-link:hover {
    background: rgba(9, 113, 184, 0.1);
  } */

  .nav-link .icon {
    font-size: 20px;
    width: 20px;
  }

  .nav-link span {
    margin-left: 9px;
    color: #ffffff;
    font-weight: 500;
    font-size: 13px;
  }
   a.nav-link.rippled {
    margin-top: -13px;
    margin: -13px;
    font-size: 10px;
   }

  .rippled {
    position: relative;
    overflow: hidden;
  }

  .rippled:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(0, 171, 148, .5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
  }
.dropdown-menu svg{
  color: black;
}
  @media only screen and (max-width: 480px) {
    li.nav-item {
      margin-left: -20px;
      }
    }
    @media only screen and (max-width: 768px) {
      li.nav-item {
        margin-left: -20px;
        }
    }

  /* @keyframes ripple {
    0% {
      transform: scale(0, 0);
      opacity: 1;
    }
    20% {
      transform: scale(50, 50);
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: scale(100, 100);
    }
  } */

  /* .rippled:focus:not(:active)::after {
    animation: ripple 1s ease-out;
  } */
</style>
