<template>
  <div class="barchart_div">
    <ag-charts-vue :options="options" v-if="dataLoaded"></ag-charts-vue>
    <div v-else>
      <h1 class="loading_para">
        Loading chart...
      </h1>
    </div>
  </div>
</template>

<script>
import { AgChartsVue } from 'ag-charts-vue';
import { getStackSummary } from '@/shared/api/dashboard-api';

export default {
  name: "Barchart",
  components: {
    'ag-charts-vue': AgChartsVue,
  },
  data() {
    return {
      dataLoaded: false,
      options: {
        data: [], // Empty data initially
        title: {
          text: "Stack count",
        },
        background: {
          fill: '#DEDEDE',
        },
        series: [], // The series will be set dynamically
        legend: { spacing: 40 },
      },
    };
  },
  async created() {
    const stackSummary = await getStackSummary();
    console.log("API Response:", stackSummary);
    this.setChartData(stackSummary);
  },
  methods: {
    setChartData(stackSummary) {
      if (stackSummary && stackSummary.stackSummary) {
        // Extract all state names dynamically from the data
        const allStateNames = [];
        stackSummary.stackSummary.forEach((item) => {
          item.states.forEach((state) => {
            if (!allStateNames.includes(state.state)) {
              allStateNames.push(state.state);
            }
          });
        });

        console.log("All State Names:", allStateNames);

        this.options.data = stackSummary.stackSummary.map((item) => {
          const dataPoint = {
            organization: item.organization,
          };

          // Update the data point with actual counts for the existing states
          item.states.forEach((state) => {
            dataPoint[state.state] = state.count;
          });

          // Initialize the data point for the missing states and set their count to 0
          allStateNames.forEach((stateName) => {
            if (!dataPoint[stateName]) {
              dataPoint[stateName] = 0;
            }
          });

          return dataPoint;
        });

        // Set the series with dynamic xKey as "organization" and yKeys as state names
        this.options.series = allStateNames.map((stateName) => ({
          type: "column",
          xKey: "organization",
          yKey: stateName,
          stacked: true,
          label: { formatter },
          title: {
            text: stateName, // Set the title for each series (optional)
          },
          stroke: "white",
          // Add other configuration for each series as needed
        }));

        // Set custom colors for different states
        const stateColors = {
          NEW: 'red',
          STOPPED: '#008FFB',
          RUNNING: '#33FF99',
        };

        // Apply custom colors to the chart series
        this.options.series.forEach((series) => {
          series.fill = stateColors[series.title.text] || 'red';
        });

        // Mark the data as loaded to render the chart
        this.dataLoaded = true;
      }
      const formatter = ({ value }) => (value == null ? '' : value.toFixed(" "));
    },
  },
};
</script>

<style scoped>
.barchart_div {
  height: 42rem;
  margin-top: 1rem;
}
.loading_para{
  text-align: center;
}

</style>



