const labsRoutes = [
  {
    path: '/labs',
    name: 'labs',
    component: () => import(/* webpackChunkName: "chunk-users" */ '@/pages/labs/labs.vue'),
    meta: {
      authorities: ['ROLE_ADMIN', 'ROLE_PROFESSOR'],
      breadcrumb: [{ text: 'Groups' }],
      title: 'AWS Cloud - Labs',
    },
    props: true,
  },
  {
    path: '/labs/:labId',
    name: 'lab_edition',
    props: true,
    component: () => import(/* webpackChunkName: "chunk-users" */ '@/pages/labs/student-assign.vue'),
    meta: {
      authorities: ['ROLE_PROFESSOR'],
      breadcrumb: [{ text: 'User Group', to: { name: 'labs' } }, { text: 'Admit Students' }],
      title: 'Operisoft - Lab User',
    },
    props: true,
  },
];

export default labsRoutes;
