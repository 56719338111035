const stacksRoutes = [
  {
    path: '/stacks',
    name: 'stacks',
    component: () => import(/* webpackChunkName: "chunk-stacks" */ '@/pages/stacks/stacks.vue'),
    meta: {
      authorities: ['ROLE_STUDENT'],
      breadcrumb: [{ text: 'Practical Assignment' }],
      title: 'Operisoft - Stacks',
    },
  },
  {
    path: '/stacks/new',
    name: 'stack_creation',
    component: () => import(/* webpackChunkName: "chunk-stacks" */ '@/pages/stacks/stack-creation.vue'),
    props: true,
    meta: {
      authorities: ['ROLE_STUDENT'],
      breadcrumb: [{ text: 'Practical Assignment', to: { name: 'stacks' } }, { text: 'Practical Configuration' }],
      title: 'Operisoft - Stack creation',
    },
  },
  {
    path: '/stacks/:stackId',
    component: () => import(/* webpackChunkName: "chunk-stacks" */ '@/pages/stacks/stack.vue'),
    meta: {
      authorities: ['ROLE_STUDENT'],
    },
    children: [
      {
        path: 'edit',
        name: 'stack_edition',
        props: true,
        component: () => import(/* webpackChunkName: "chunk-stacks" */ '@/pages/stacks/stack-edition.vue'),
        meta: {
          authorities: ['ROLE_STUDENT'],
          breadcrumb: [{ text: 'Practical Assignment', to: { name: 'stacks' } }, { text: 'Practical Info.' }],
          title: 'Operisoft - Stack edition',
        },
      },
      // {
      //   path: 'view students stacks',
      //   name: 'StudentStacks',
      //   props: true,
      //   component: () => import(/* webpackChunkName: "chunk-stacks" */ '@/pages/stacks/students-stacks.vue'),
      //   meta: {
      //     authorities: ['ROLE_STUDENT'],
      //     breadcrumb: [{ text: 'Stacks', to: { name: 'stacks' } }, { text: 'Stack edition' }],
      //     title: 'Operisoft - Stack edition',
      //   },
      // },
      {
        path: 'assignment',
        name: 'student_assignment',
        props: true,
        component: () => import(/* webpackChunkName: "chunk-stacks" */ '@/pages/stacks/student-assignment.vue'),
        meta: {
          authorities: ['ROLE_STUDENT'],
          breadcrumb: [{ text: 'Practical Assignment', to: { name: 'stacks' } }, { text: 'Student Assignment' }],
          title: 'Operisoft - Student Assign',
        },
      },
      {
        path: 'settings',
        name: 'stack_setting',
        props: true,
        component: () => import(/* webpackChunkName: "chunk-stacks" */ '@/pages/stacks/stack-setting.vue'),
        meta: {
          authorities: ['ROLE_STUDENT'],
          breadcrumb: [{ text: 'Practical Assignment', to: { name: 'stacks' } }, { text: 'Practical Setting' }],
          title: 'Operisoft - Stacks Setting',
        },
      },
      {
        path: 'jobs/:jobId',
        name: 'job',
        component: () => import(/* webpackChunkName: "chunk-stacks" */ '@/pages/stacks/job/job.vue'),
        props: true,
        meta: {
          authorities: ['ROLE_STUDENT'],
          breadcrumb: [
            { text: 'Practical Assignment', to: { name: 'stacks' } },
            { text: 'Practical Assignment', to: { name: 'stack_edition' } },
            { text: 'Job' },
          ],
          title: 'Operisoft - Stack job',
        },
      },
    ],
  },
];

export default stacksRoutes;
