<template>
  <div class="footer_main">
    <div>
      <div>
        <div class="social_media_box">
          <a href="https://twitter.com/operisoft">
           <span><font-awesome-icon :icon="['fab', 'twitter']" /></span>
          </a>
          <a href="https://www.facebook.com/operisoft">
            <span><font-awesome-icon :icon="['fab', 'facebook']" /></span>
          </a>
          <a href="https://www.instagram.com/operisoft_technologies/">
            <span> <font-awesome-icon :icon="['fab', 'instagram']" /></span>
          </a>
          <a href="https://www.youtube.com/channel/UCKKwIii4SnbG0NjBGPe-K4A">
            <span> <font-awesome-icon :icon="['fab', 'youtube']" /></span>
          </a>
          <a href="https://www.linkedin.com/company/operisoft">
            <span> <font-awesome-icon :icon="['fab', 'linkedin']" /></span>
          </a>
        </div>
          <div class="links">
            <div class="ftr_link_box">
              <a
              href="#"
              class="footer_link"
              >Home
            </a>
            </div>
            <div class="ftr_link_box">
              <a
              href="#"
              class="footer_link"
              >Services</a>
            </div>
            <div class="ftr_link_box">
              <a
              href="#"
              class="footer_link"
              >Contact Us</a>
            </div>
            <div class="last_link_box">
              <a
              href="#"
              class="footer_link"
              >About us</a>
            </div>
          </div>
        </div>
        <div class="copyright">
          <p class="copyright_para">
            © 2023 | Powered by Operisoft
          </p>
        </div>
      </div>
    </div>
</template>

<script>
import {
   faTwitterSquare,
   faFacebook,
    faInstagramSquare,
    faYoutubeSquare,
    faLinkedinIn,
   } from '@fortawesome/free-brands-svg-icons';

export default {
  name: 'AppFooter',
  components: {
    faTwitterSquare,
    faFacebook,
    faInstagramSquare,
    faYoutubeSquare,
    faLinkedinIn,
  },
};
</script>

<style scoped>
.footer_main {
  background-color:#073065;
  padding: 20px;
  width: 100%;
  padding: 20px;
  /* flex: 4;
  order: 4; */
}
.social_media_box {
  display: flex;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
}
.social_media_box span{
  font-size: 25px;
  margin-left: 10px;
}

.links {
  display: flex;
  justify-content: center;
  margin-left: 15px;
  flex-wrap: wrap;
  flex-direction: row;
}

.links a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  margin-left: 20px;

}

.ftr_link_box {
  padding: 2px 10px;
  border-right: 1px solid rgb(255, 255, 255);
}
.last_link_box{
  padding: 2px 10px;
}

.copyright_para {
  color: white;
  text-align: center;
}

.copyright {
  margin-top: 20px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 480px) {
  .links{
    flex-direction: column;
    margin-left: 0px;
    align-items: center;
    }
    .ftr_link_box{
      border: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .links{
      flex-direction: column;
      margin-left: 0px;
    align-items: center;
      }
      .ftr_link_box{
        border: none;
      }
    }
</style>
