<template>
  <div
    id="dashboard_row"
    class="container-fluid mt-5 mb-5"
  >
    <div class="row p-2">
      <div
        v-if="authorities.includes('ROLE_PROFESSOR')"
        id="dashboard_cols"
        class="col-md-4
        margin_bottom_30"
      >
        <b-overlay :show="loading">
          <app-dashboard-widget
            class="blue2_bg"
            text="Modules"
            :value="summary.modulesCount"
            icon="object-group"
            :to="{ name: 'modules' }"
          />
        </b-overlay>
      </div>
      <div class="col-md-4  margin_bottom_30">
        <b-overlay :show="loading">
          <app-dashboard-widget
            class="green_bg"
            text="Running Stacks"
            :value="summary.runningStacksCount"
            icon="layer-group"
            :to="{ name : 'stacks' }"
          />
        </b-overlay>
      </div>
      <div class="col-md-4 margin_bottom_30">
        <b-overlay :show="loading">
          <app-dashboard-widget
            class="yellow_bg"
            text="Stacks to update"
            :value="summary.toUpdateStacksCount"
            icon="caret-square-up"
            :to="{ name : 'stacks' }"
          />
        </b-overlay>
      </div>
    </div>
    <div
      v-if="isWithoutOrganization"
      class="center"
    >
      <div class="error_page">
        <div class="center">
          <img
            alt="#"
            class="img-responsive"
            src="@/assets/images/waving-hand-sign.png"
          >
        </div>
        <br>
        <h3>Hi there !</h3>
        <p>It seems that you're not a member of a organization yet. Ask your admin to add you a organization !</p>
        <p>Until then, you'll only be able to use public modules.</p>
      </div>
    </div>
     <div
      v-if="authorities.includes('ROLE_PROFESSOR')"
      id="chart_container"
      class="container-fluid"
    >
      <div class="row d-flex justify-content-around">
        <div
          id="donut_chart_row"
          class="col-md-5"
        >
          <donutchart />
        </div>
         <div
          id="bar_chart_row"
          class="col-md-6"
        > 
           <apexbarchart /> 
         </div>
      </div>
    </div>
  </div>
  <!-- <div
  class="centered-div"
  >
      <div
      v-if="isWithoutOrganization"
      class="center"
    >
      <div class="error_page">
        <div class="center">
          <img
            alt="#"
            class="img-responsive"
            src="@/assets/images/waving-hand-sign.png"
          >
        </div>
        <br>
        <h3>Hi there !</h3>
        <p>It seems that you're not a member of a organization yet. Ask your admin to add you a organization !</p>
        <p>Until then, you'll only be able to use public modules.</p>
      </div>
    </div>
  <div>
    <img
      alt="#"
      class="img-responsive"
      src="@/assets/images/waving-hand-sign.png"
    >
      <h1
        class="comming_soon"
      >
        Coming soon
      </h1>
  </div>
  </div> -->
</template>

<script>
  import { mapState } from 'vuex';
  import AppDashboardWidget from '@/pages/dashboard/dashboard-widget.vue';
  import { getSummary, getUserSummary, getStackSummary } from '@/shared/api/dashboard-api';
  import { getAuthorities } from '@/shared/api/authentication-api';
  import apexbarchart from '../../shared/components/dashboardchart/apexbarchart.vue';
  import donutchart from '../../shared/components/dashboardchart/donutchart.vue';

  export default {
    name: 'AppDashboard',
    components: {
      AppDashboardWidget,
      apexbarchart,
      donutchart,
    },
    data: () => ({
      loading: true,
      summary: { modulesCount: 0, runningStacksCount: 0, toUpdateStacksCount: 0 },
        // eslint-disable-next-line
      userSummary: {},
      stackSummary: {},
      authorities: [],
    }),
    computed: {
      ...mapState('session', ['user']),
      isWithoutOrganization() {
        return !this.user.admin && !this.user.organization;
      },
    },
    async created() {
      this.authorities = await getAuthorities();
      this.summary = await getSummary();
      this.userSummary = await getUserSummary();
      this.stackSummary = await getStackSummary();
      this.loading = false;
    },
  };
</script>
<style scoped >
.analiticaldata{
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
.barchart_container{
  padding-top: 20px;
  padding-bottom: 20px;
}
.donutchart_container{
    background-color: #DEDEDE;
    border-radius: 5px;
    padding: 10px;
    height: 500px;
}
.barchart_container{
    background-color: #DEDEDE;
    border-radius: 5px;
    padding: 10px;
    height: 500px;
}
.chart_container{
  margin-top: 10px;
  margin-bottom: 10px;
}
#donut_chart_row,#bar_chart_row{
  background-color: #DEDEDE;
  border-radius: 10px;
}
.apexcharts-legend.apexcharts-align-center.apx-legend-position-right{
  top: 0px;
  position: absolute;
  left: auto;
    /* top: 24px; */
  right: 5px;
}
@media only screen and (max-width: 480px) {
  #donut_chart_row,#bar_chart_row {
    margin: 20px;
    }
  }
  @media only screen and (max-width: 768px) {
  #donut_chart_row,#bar_chart_row {
    margin: 20px;
    }
  }

/* .comming_soon {
  color: #15088b;
  padding-top: 30px;
  font-family: 'Coiny', cursive;
  text-align: center;
  font-size: 70px;
-webkit-animation: bounce .4s ease infinite alternate;
}
@-webkit-keyframes bounce {
  0% {
      text-shadow:
      0 5px 0 #ccc,
      0 2px 3px rgba(0, 0, 0, 1);
  }
  100% {
    transform:translateY(-20px);
    text-shadow:  0 50px 0 #black,
    0 0px 20px rgba(0, 0, 0, .8);
  }
}
.img-responsive{
  margin: auto;
  display: block;
}
  .centered-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } */
</style>
