<template>
  <div class="full_container">
    <app-top-bar />
    <div class="inner_container">
      <!-- <app-side-bar @collapse="expandContent = $event" /> -->
      <div class="sidebar_div">
        <app-side-bar />
      </div>
      <!-- <div :class="expandContent ? 'content expand' : 'content'"> -->
      <div class="content">
        <div class="container-fluid">
          <div class="row column_title">
            <div class="col-md-12">
              <div class="page_title">
                <app-breadcrumb />
              </div>
            </div>
          </div>
          <slot />
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="footer_container">
            <App-Footer />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    AppBreadcrumb,
    AppSideBar,
    AppTopBar,
  } from '@/shared/components';
  import AppFooter from '../shared/components/footer.vue/Appfooter.vue';

  export default {
    name: 'AppDefaultLayout',
    components: {
      AppTopBar,
      AppSideBar,
      AppFooter,
      AppBreadcrumb,
    },
    // data: () => ({
    //   expandContent: false,
    // }),

    mounted() {
      document.body.classList.add('dashboard');
    },
    destroyed() {
      document.body.classList.remove('dashboard');
    },
  };
</script>

<style scoped>
.inner_container {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.content {
  width: 100%;
  /* min-height: 100vh; */
  /* transition: ease all 0.3s; */
  /* position: relative; */
  /* padding: 60px 25px 25px 265px; */
   background: #ffffff;
  /* margin-top: 10px; */
  flex: 3;
  order: 3;
  min-height: 75vh;
}

.sidebar_div {
  order: 2;
  width: 100%;
}

/* .content.expand {
    padding-left: 95px;
  }  */
@media (max-width: 1199px) {
  /* .content {
      padding-left: 95px;
    }  */
}

.footer_container {
  display: flex;
  align-items: flex-end;
}
</style>
