/* eslint-disable */
<template>
  <div class="example">
    <apexchart
      :width="width"
      :height="height"
      :options="options"
      :series="data"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { getUserSummary } from "@/shared/api/dashboard-api";

export default {
  name: "Donutchart",
  props: {
    title: {
      type: String,
      default: "Labwise Student count",
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      data: [],
      labels: [],
    };
  },
  // async mounted() {
  //   await this.loadData();
  //   this.$refs.chart.updateOptions(this.options);
  // },
  async created() {
    const stackSummary = await getUserSummary();
    this.data = Object.values(stackSummary.data).map((item) => item.count);
    this.labels = Object.values(stackSummary.data).map((item) => item._id);
  },
  computed: {
    options() {
      return {
        chart: {
          type: "pie",
        },
        title: {
          text: this.title,
          align: "start",
          style: {
            fontSize: "20px",
            fontWeight: 'normal',
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: "Total",
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "white",
                },
              },
            },
          },
        },
        legend: {
          position: 'bottom'
        },
        noData: {
          text: this.loading ? "Loading..." : "No Data",
        },
        labels: this.labels,
      };
    },
  },
};
</script>
<style scoped>

</style>

