<template>
  <div class="container-fluid">
<div class="row">
        <b-navbar
        toggleable="lg"
        type="light"
        >
          <div class="col-md-6">
          <b-navbar-brand :to="{ name: 'home' }">
            <img
            class="img-responsive logo"
            src="@/assets/images/2_logo_final.png"
            alt="#"
            >
          </b-navbar-brand>
       </div>
       <div class="col-md-6">
      <b-navbar-nav class="justify-content-end">
        <app-top-bar-user />
      </b-navbar-nav>
    </div>
      </b-navbar>
</div>
  </div>
</template>

<script>
import AppTopBarUser from '@/shared/components/topbar/top-bar-user.vue';

export default {
  name: 'AppTopBar',
  components: {
    AppTopBarUser,
  },
};
</script>

<style scoped>
.navbar {
  background: #F1F1F1;
  border-bottom: 1px solid #C08888;
  /* position: fixed; */
  padding: 0;
  width: 100%;
  /* z-index: 2; */
}

.logo {
  height: 50px;
  margin: 0 30%;
  width: 150px;
}
</style>
